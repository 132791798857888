import React from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";

import img1 from "../../assets/images/common/icon_1.png";
import img2 from "../../assets/images/common/icon_2.png";
import img3 from "../../assets/images/common/icon_3.png";
import img4 from "../../assets/images/common/icon_1.png";
import img5 from "../../assets/images/common/icon_4.png";
import img6 from "../../assets/images/common/icon_5.png";
import img7 from "../../assets/images/common/icon_6.png";
import img8 from "../../assets/images/common/icon_7.png";
import img9 from "../../assets/images/common/icon_8.png";

const dataFeatured = [
  {
    id: 1,
    img: img1,
    title: "Potential Project",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 2,
    img: img2,
    title: "High Feasibility",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 3,
    img: img3,
    title: "Easy Withdrawal",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 4,
    img: img4,
    title: "Potential Project",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },

  {
    id: 5,
    img: img5,
    title: "A virtual world",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 6,
    img: img6,
    title: "Virtual reality",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 7,
    img: img7,
    title: "Other people",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 8,
    img: img8,
    title: "Persistence",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 9,
    img: img9,
    title: "Connect with the world",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
];

function Featured() {
  return (
    <section className="tf-section fueture">
      <div className="container w_1320">
        <div className="row">
          <div className="col-md-12">
            <div className="container_inner">
              <Swiper
                modules={[Navigation]}
                spaceBetween={30}
                slidesPerView={3}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },

                  867: {
                    slidesPerView: 2,
                  },

                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {dataFeatured.slice(0, 4).map((item) => (
                  <SwiperSlide className="pb-30" key={item.id}>
                    <div className="icon-box">
                      <div className="top">
                        <div className="icon">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="content">
                          <h5>
                            <Link to="/project_list">{item.title}</Link>
                          </h5>
                          <p>{item.text}.</p>
                        </div>
                      </div>
                      <div className="bottom">
                        <img
                          src={require("../../assets/images/backgroup/bg_bt_box_1.png")}
                          alt=""
                        />
                      </div>
                      <div className="number">{item.id}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="col-md-12 mt30">
                <p className="desc text-center mb20">
                  At Risebot, we bring projects with great potential that bring
                  huge profits <br className="show-destop" />
                  auctor euismod magna etiam diam velit, ultrices vel feugiat a,
                  varius quis felis. Integer tempor efficitur mollis
                </p>
                <div className="wrap-btn justify-content-center">
                  <Link to="/submit_igo" className="tf-button style1">
                    JOIN US
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Featured;
