import React from "react";

import { Chart } from "../components/token/Chart";
import CTA from "./components/Cta";
import PageTitle from "./components/PageTitle";

function Token(props) {
  return (
    <div className="inner-page">
      {<PageTitle title="Token" />}

      <section className="tf-section tf-token pt60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="tf-title left mb40"
                data-aos-delay="500"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h2 className="title">Token Distribution</h2>
              </div>
              <div
                className="desc-token"
                data-aos-delay="500"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="total-token">
                  <p>
                    Total Supply: <span>15,000,000</span>
                  </p>
                  <p>
                    Public sale: <span> $0.24</span>
                  </p>
                </div>
                <ul className="describe_chart">
                  <li>
                    <img
                      src={require("../assets/images/chart/color_1.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Farming Pool</p>
                      <p className="number">27.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_2.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Staking</p>
                      <p className="number">15.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_3.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Ecosystem</p>
                      <p className="number">7.5%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_4.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Advisor</p>
                      <p className="number">7.03%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_5.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Private Sale</p>
                      <p className="number">23.45%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_6.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Liquidity</p>
                      <p className="number">13.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_7.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Marketing</p>
                      <p className="number">7.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_8.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Team</p>
                      <p className="number">5.3%</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                data-aos="fade-left"
                data-aos-delay="500"
                data-aos-duration="800"
              >
                <div id="chart">
                  <Chart />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6">
              <div
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="800"
              >
                <div id="chart">
                  <Chart />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="tf-title left mb40"
                data-aos-delay="500"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h2 className="title">Funds Allocation</h2>
              </div>
              <div
                className="desc-token"
                data-aos-delay="500"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="total-token">
                  <p>
                    Total Supply: <span>15,000,000</span>
                  </p>
                  <p>
                    Public sale: <span> $0.24</span>
                  </p>
                </div>
                <ul className="describe_chart">
                  <li>
                    <img
                      src={require("../assets/images/chart/color_1.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Farming Pool</p>
                      <p className="number">27.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_2.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Staking</p>
                      <p className="number">15.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_3.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Ecosystem</p>
                      <p className="number">7.5%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_4.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Advisor</p>
                      <p className="number">7.03%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_5.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Private Sale</p>
                      <p className="number">23.45%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_6.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Liquidity</p>
                      <p className="number">13.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_7.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Marketing</p>
                      <p className="number">7.3%</p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/chart/color_8.png")}
                      alt=""
                    />
                    <div className="desc">
                      <p className="name">Team</p>
                      <p className="number">5.3%</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {<CTA />}
    </div>
  );
}

export default Token;
