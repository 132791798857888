import React, { useEffect, useRef, useState } from "react";
import networkInfo from "./networkInfo";
import { useAccount, useConnect, useEnsName } from "wagmi";
import { getNetwork, switchNetwork, watchNetwork } from "@wagmi/core";
import { useGlobalState } from "./hooks/globalState";
import PageTitle from "./components/PageTitle";
import { toast } from "react-toastify";
import ProgressBar from "react-bootstrap/ProgressBar";
import CustomSwitch from "./components/CustomSwitch";
import CustomToolTip from "./components/ToolTipEx";
import WarningAlert from "./components/WarningAlert";
import ConnectButton from "./components/ConnectButton";
import Button from "./components/button";
import { Spinner } from "react-bootstrap";
import { addDecimal, getApprove, removeDecimal } from "./hooks/wagmiHooks";
import info from "./info";
import { Link } from "react-router-dom";

function Tokensender() {
  const { address, isConnected } = useAccount();
  const { chain } = getNetwork();
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [deflationary, setdeflationary] = useState(false);
  const [showExampleList, setShowExampleList] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [lines, setLines] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [mergeArray, setMergeArray] = useState([]);
  const [dataArray, setDataArray] = useState(null);
  const [checking, setchecking] = useState(false);
  const [errorIndex, setErrorIndex] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);

  const textareaRef = useRef();
  const divRef = useRef();

  return (
    <>
      <div className="inner-page project-grid-5">
        {<PageTitle title="Project Details" />}
        <div>
          <section className="tf-section details">
            <div className="container">
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="sidebar">
                  <div className="prodect-content">
                    <div className="box">
                      <div className="row">
                        <div className="col-9">
                          Mode
                          <h4>
                            {deflationary ? "Buy Tokens" : "Presale Info"}
                          </h4>
                        </div>
                        <div className="col-3">
                          Buy
                          <br />
                          <CustomSwitch
                            checked={deflationary}
                            onChange={() => setdeflationary((pre) => !pre)}
                          />
                        </div>
                      </div>
                      <div className="inner-box">
                        {!deflationary ? (
                          <>
                            <h6>Currently Sold</h6>
                            <ProgressBar
                              striped
                              animated
                              variant="primary"
                              now={45}
                            />
                            <ul className="mt-4">
                              <li>
                                <p>Total Tokens Sold:</p>
                                <p> 378,000</p>
                              </li>
                              <li>
                                <p>Matic Raised:</p>
                                <p>178,000</p>
                              </li>
                              <li>
                                <p>Price:</p>
                                <p>1 Matic : 1000 Token</p>
                              </li>
                              <li>
                                <p>Your Matic Balance:</p>
                                <p>200</p>
                              </li>
                              <li>
                                <p>Your Token Balance:</p>
                                <p>0</p>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <form
                                  action="contact/contact-process.php"
                                  className="form-contact"
                                >
                                  <div className="project-info-form">
                                    <div className="form-inner">
                                      <div className="row">
                                        <div className="col-md-6 mt-3">
                                          <fieldset>
                                            <label>Pay Matic</label>
                                            <div style={{ display: "flex" }}>
                                              <img
                                                src="https://placeholder.co/46"
                                                alt=""
                                                style={{
                                                  borderTopLeftRadius: "10px",
                                                  borderBottomLeftRadius:
                                                    "10px",
                                                }}
                                              />
                                              <input
                                                type="text"
                                                id="name"
                                                placeholder="Enter Matic Amount"
                                                required
                                                autoComplete="off"
                                                style={{
                                                  borderTopLeftRadius: "0px",
                                                  borderBottomLeftRadius: "0px",
                                                  borderTopRightRadius: "10px",
                                                  borderBottomTightRadius:
                                                    "10px",
                                                }}
                                              />
                                            </div>
                                          </fieldset>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                          <fieldset>
                                            <label>Get Token</label>
                                            <div style={{ display: "flex" }}>
                                              <img
                                                src="https://placeholder.co/46"
                                                alt=""
                                                style={{
                                                  borderTopLeftRadius: "10px",
                                                  borderBottomLeftRadius:
                                                    "10px",
                                                }}
                                              />
                                              <input
                                                type="text"
                                                id="name"
                                                placeholder="Enter Token Amount"
                                                required
                                                autoComplete="off"
                                                style={{
                                                  borderTopLeftRadius: "0px",
                                                  borderBottomLeftRadius: "0px",
                                                  borderTopRightRadius: "10px",
                                                  borderBottomTightRadius:
                                                    "10px",
                                                }}
                                              />
                                            </div>
                                          </fieldset>
                                        </div>
                                        <div
                                          className="col-12 mt-3"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <button
                                            type="submit"
                                            className="tf-button style1"
                                          >
                                            Buy
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Tokensender;
