import { React, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import AOS from "aos";
import "../src/assets/fonts/font-awesome.css";
import Home from "./cpages/Home";
import Contact from "./cpages/Contact";
import Header from "./cpages/components/header";
import Footer from "./cpages/components/footer";
import Tokensender from "./cpages/Tokensender";
import { WagmiConfig, createConfig } from "wagmi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GlobalStateProvider,
  useGlobalState,
} from "./cpages/hooks/globalState";
import { sepolia } from "@wagmi/core/chains";
import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultConfig,
} from "connectkit";
import Roadmap from "./cpages/Roadmap";
import Token from "./cpages/Token";

function App1() {
  const { globalState, setGlobalState } = useGlobalState();

  const config = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: "BwE39efXkhwLUi3Zla2UthiCF38_upT3", // or infuraId
      walletConnectProjectId: "734adb9155ad6e52e3c09a08880f1bb7",

      // Required
      appName: "Your App Name",

      // Optional
      chains: [sepolia],
      appDescription: "Your App Description",
      appUrl: "https://uncw3b.com", // your app's url
      appIcon:
        "https://uncw3b.com/1694615642228-5321572a-477a-4243-9da1-e1782b8d4583.webp", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    })
  );

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <WagmiConfig config={config}>
        <ConnectKitProvider>
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/token" element={<Token />} />

            <Route path="/contact" element={<Contact />} />
            <Route path="/presale" element={<Tokensender />} />
          </Routes>

          <Footer />
        </ConnectKitProvider>
      </WagmiConfig>
    </>
  );
}

function App2() {
  return (
    <GlobalStateProvider>
      <App1 />
    </GlobalStateProvider>
  );
}

export default App2;
