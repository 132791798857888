import React from "react";
import { ConnectKitButton } from "connectkit";
import Button from "./button";

function ConnectButton() {
  function truncateEthereumAddress(address) {
    const prefix = address.substring(0, 2); // '0x' öneki
    const truncatedAddress =
      address.substring(0, 6) + "..." + address.substring(address.length - 4);
    return truncatedAddress;
  }
  return (
    <ConnectKitButton.Custom showBalance={true}>
      {({ isConnected, isConnecting, show, hide, address, ensName, chain }) => {
        return (
          <Button
            title={
              isConnected ? truncateEthereumAddress(address) : "Connect Wallet"
            }
            onClick={show}
            addclass="style1"
          />
        );
      }}
    </ConnectKitButton.Custom>
  );
}

export default ConnectButton;
