const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },

  {
    id: 3,
    name: "Roadmap",
    links: "/roadmap",
  },
  {
    id: 4,
    name: "Token",
    links: "/token",
  },
  {
    id: 5,
    name: "Presale",
    links: "/presale",
  },

  /* {
    id: 2,
    name: "Apps",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Batch Sender",
        links: "/batchsender",
      },
      {
        id: 2,
        sub: "NFT Batch Sender",
        links: "/nftsender",
      },
    ],
  },*/
  {
    id: 6,
    name: "Contact",
    links: "contact",
  },
];

export default menus;
