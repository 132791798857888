import React from "react";

import Banner from "./components/banner/Banner";
import Featured from "./components/Featured";
import Technology from "./components/Technology";
import Faqs from "./components/Faqs";
import CTA2 from "./components/Cta2";
import CTA from "./components/Cta";
import dataPartner from "../assets/fake-data/data-partner";
import Step from "./components/Step";
import Partner4 from "./components/Partner";

function Home() {
  return (
    <div className="header-fixed main home2 counter-scroll">
      {<Banner />}

      {<Featured />}

      <div className="bg_body">
        <div className="bg_h2">
          <img
            src={require("../assets/images/backgroup/bg_home2.png")}
            alt=""
          />
        </div>

        {<Technology />}
      </div>

      {<CTA2 />}
      {<Step />}

      {<Partner4 />}
      {<Faqs />}

      {<CTA />}
    </div>
  );
}

export default Home;
